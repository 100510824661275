.ChangePasswordForm {
    width: 500px;
    position: relative;
    left: 30%;
    border: 1px solid;
    border-radius: 20px;
    min-height: 500px;
    margin-top: 10%;
    padding-top: 3%;
    display: table;
}

.InputControl {
    left: 25%;
}

.ErrorDiv {
    margin-left: 25%;
    color: red;
    margin-top: 2%;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}

.InputContainer span {
    color: red;
    word-wrap: normal;
    text-align: center;
}

.InputContainer ul {
    color: red;
    word-wrap: normal;
    margin: 0;
    margin-left: 25%;
}
