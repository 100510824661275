.FilterFlexContainer {    
    display: flex;
    justify-content: space-between;
}

.FilterContainerItem{
    width: 50%;
    padding-left: 15%;
}

.TabContainer{
    display: flex;
    justify-content: space-evenly;
}
